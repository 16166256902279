<script setup>
defineProps({
  color: {
    type: String,
    default: '#fff',
  },
})

const { updateDrawerState } = useDrawer()

function openMenu() {
  const opt = {
    menuPositionClass: 'left',
    title: 'Menu',
    component: 'MobileMenu',
  }

  updateDrawerState({
    drawerOptions: opt,
    isDrawerShown: true,
  })
}
</script>

<template>
  <BaseButton
    aria-label="hamburger menu"
    variant="naked"
    class="reset-btn-styling d-flex flex-column justify-content-center align-items-center position-relative"
    @click="openMenu"
  >
    <span class="line" :style="{ backgroundColor: color }"></span>
    <span class="line" :style="{ backgroundColor: color }"></span>
    <span class="line" :style="{ backgroundColor: color }"></span>
  </BaseButton>
</template>

<style lang="scss" scoped>
button {
  width: 24px;
  height: 24px;

  &:focus {
    .line {
      opacity: 0;

      &:nth-child(1) {
        transform: rotate(20deg);
      }

      &:nth-child(3) {
        transform: rotate(-20deg);
        position: absolute;
        top: 6px;
      }

      &:nth-child(2) {
        transition: none;
        opacity: 0;
      }
    }
  }
}
.line {
  transition: all 0.3s ease-out;
  display: block;
  height: 2px;
  width: 20px;
  border-radius: 2px;
  margin: 2px 0;
  opacity: 1;
}
</style>
